import { createApp } from 'vue';
import i18n from './plugins/i18n';

import App from './App.vue';
import router from './router';

createApp(App, {
    // methods: {
    //     changeLocale(test) {
    //         console.log("here " + test);
    //     }
    // }
}
).use(router).use(i18n).mount('#app');
