<template>
  <div>
    <Menu navGlobalMenu="products" navSubmenu="products" />
    <section>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2>{{ $t('productsHeader') }}</h2>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb fs--14">
                <li class="breadcrumb-item">
                  <router-link to="/">{{ $t('homeHeader') }}</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  <span>{{ $t('productsHeader') }}</span>
                </li>
              </ol>
            </nav>
            <hr />

            <div class="row">
              <div class="col-12">
                <p>{{ $t('productsSubheader') }} <router-link to="/contacts">{{ $t('contactsHeader') }}</router-link>.</p>
              </div>
            </div>

            <div class="row">

              <div class="col-6 col-lg-3 mb-5">
                <div class="bg-white p-2 shadow-primary-xs transition-hover-top transition-all-ease-250">
                  <router-link class="d-block overflow-hidden overlay-dark-hover overlay-opacity-2 text-decoration-none text-dark" to="/products/blinds">
                    <img class="img-fluid lazy rounded fillWidth" src="@/assets/images/products/main/blinds.jpg" alt="..." data-loaded="true">
                  </router-link>
                  <div class="p-3">
                    <h5 class="m-0">
                      <span>{{ $t('productsBlinds') }}</span>
                    </h5>
                  </div>
                </div>
              </div>

              <div class="col-6 col-lg-3 mb-5">
                <div class="bg-white p-2 shadow-primary-xs transition-hover-top transition-all-ease-250">
                  <router-link class="d-block overflow-hidden overlay-dark-hover overlay-opacity-2 text-decoration-none text-dark" to="/products/air-conditions">
                    <img class="img-fluid lazy rounded fillWidth" src="@/assets/images/products/main/air-conditions.jpg" alt="..." data-loaded="true">
                  </router-link>
                  <div class="p-3">
                    <h5 class="m-0">
                      <span>{{ $t('productsAirConditions') }}</span>
                    </h5>
                  </div>
                </div>
              </div>

              <div class="col-6 col-lg-3 mb-5">
                <div class="bg-white p-2 shadow-primary-xs transition-hover-top transition-all-ease-250">
                  <router-link class="d-block overflow-hidden overlay-dark-hover overlay-opacity-2 text-decoration-none text-dark" to="/products/infrared-panels">
                    <img class="img-fluid lazy rounded fillWidth" src="@/assets/images/products/main/infrared-panels.jpg" alt="..." data-loaded="true">
                  </router-link>
                  <div class="p-3">
                    <h5 class="m-0">
                      <span>{{ $t('productsInfraredPanelsShort') }}</span>
                    </h5>
                  </div>
                </div>
              </div>

              <div class="col-6 col-lg-3 mb-5">
                <div class="bg-white p-2 shadow-primary-xs transition-hover-top transition-all-ease-250">
                  <router-link class="d-block overflow-hidden overlay-dark-hover overlay-opacity-2 text-decoration-none text-dark" to="/products/thermo-paints">
                    <img class="img-fluid lazy rounded fillWidth" src="@/assets/images/products/main/thermo-paints.jpg" alt="..." data-loaded="true">
                  </router-link>
                  <div class="p-3">
                    <h5 class="m-0">
                      <span>{{ $t('productsThermoPaints') }}</span>
                    </h5>
                  </div>
                </div>
              </div>

              <div class="col-6 col-lg-3 mb-5">
                <div class="bg-white p-2 shadow-primary-xs transition-hover-top transition-all-ease-250">
                  <router-link class="d-block overflow-hidden overlay-dark-hover overlay-opacity-2 text-decoration-none text-dark" to="/products/windows">
                    <img class="img-fluid lazy rounded fillWidth" src="@/assets/images/products/main/windows.jpg" alt="..." data-loaded="true">
                  </router-link>
                  <div class="p-3">
                    <h5 class="m-0">
                      <span>{{ $t('productsWindows') }}</span>
                    </h5>
                  </div>
                </div>
              </div>

              <div class="col-6 col-lg-3 mb-5">
                <div class="bg-white p-2 shadow-primary-xs transition-hover-top transition-all-ease-250">
                  <router-link class="d-block overflow-hidden overlay-dark-hover overlay-opacity-2 text-decoration-none text-dark" to="/products/nets">
                    <img class="img-fluid lazy rounded fillWidth" src="@/assets/images/products/main/nets.jpg" alt="..." data-loaded="true">
                  </router-link>
                  <div class="p-3">
                    <h5 class="m-0">
                      <span>{{ $t('productsNets') }}</span>
                    </h5>
                  </div>
                </div>
              </div>

              <div class="col-6 col-lg-3 mb-5">
                <div class="bg-white p-2 shadow-primary-xs transition-hover-top transition-all-ease-250">
                  <router-link class="d-block overflow-hidden overlay-dark-hover overlay-opacity-2 text-decoration-none text-dark" to="/products/doors">
                    <img class="img-fluid lazy rounded fillWidth" src="@/assets/images/products/main/doors.jpg" alt="..." data-loaded="true">
                  </router-link>
                  <div class="p-3">
                    <h5 class="m-0">
                      <span>{{ $t('productsDoors') }}</span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <CallToAction />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { title } from "../../utils/utils";
import Menu from "../Menu.vue";
import CallToAction from "../fragments/CallToAction.vue";
// import ImageCard from "../fragments/ImageCard.vue";

export default {
  name: "Products",
  components: {
    Menu,
    CallToAction,
    // ImageCard,
  },
  created() {
    // document.title = "Home | Magnum Opus";
    document.title = title(this.$t("productsHeader"), this.$t("appName"));
  },
};
</script>
