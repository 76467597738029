<template>
  <div>
    <Menu navGlobalMenu="" />
    <section class="pt-0">
      <div class="container text-center pt-5">

        <span class="badge badge-pill badge-danger badge-soft font-weight-light pl-2 pr-2 pt--6 pb--6 mb-2">{{ $t('notFoundSubHeader') }}</span>

        <h2 class="mb--100 font-weight-normal">{{ $t('notFoundHeader') }}</h2>

        <!-- image -->
        <img class="opacity-6 max-w-450 img-fluid" src="@/assets/images/not_found.svg" alt="...">

        <div class="mt-5">
          <router-link class="btn btn-danger shadow-danger-xlg row-pill font-weight-medium" to="/products">{{ $t('homeProducts') }}</router-link>
        </div>

      </div>
    </section>
  </div>
</template>

<script>
import Menu from "../components/Menu.vue";
import { title } from "../utils/utils";

export default {
  name: "NotFound",
  components: {
    Menu,
  },
  created() {
    // document.title = "Home | Magnum Opus";
    document.title = title(this.$t("notFoundTitle"), this.$t("appName"));
  },
};
</script>

<style>
@import "../assets/css/core.css";
@import "../assets/css/vendor_bundle.min.css";
@import "../assets/css/vendor.photoswipe.css";
@import "../assets/css/style.css";
</style>

