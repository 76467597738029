<template>

  <div>
    <router-view :key="$route.fullPath" />
    <Footer />
  </div>

</template>

<script>
import i18n from "./plugins/i18n";
import Footer from "./components/Footer.vue";
import cookies from 'vue-cookies';

export default {
  name: "App",
  components: {
    Footer
  },
  created() {
    // alert("here");
    // alert("locale: " + i18n.global.locale);
    // cookies.set('locale', 'bg');
    // alert(cookies.get('locale'));
    if (cookies.get('locale') !== null) {
      i18n.global.locale = cookies.get('locale');
    }
  }
};
</script>
