export const title = (title, name) => {
    if (title) {
        return title + " | " + name;
    } else {
        return name;
    }
}

// export default {
//     setTitle(title) {
//         if (title) {
//             return title + " | Magnum Opus";
//         } else {
//             return "Magnum Opus"
//         }
//     }
// }