<template>
  <div>
    <Menu navGlobalMenu="contacts" />
    <section>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2>{{ $t('contactsHeader') }}</h2>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb fs--14">
                <li class="breadcrumb-item">
                  <router-link to="/">{{ $t('homeHeader') }}</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  <span>{{ $t('contactsHeader') }}</span>
                </li>
              </ol>
            </nav>
            <hr />
            <p class="mb-4">{{ $t('contactsSubheader') }}</p>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-12 col-lg-8 mb-4">
                <form @submit.prevent="sendEmail" data-ajax-container="#ajax_dd_contact_response_container" data-ajax-update-url="false" data-ajax-show-loading-icon="true" data-ajax-callback-function="" data-error-scroll-up="false" data-ajax-control-alerts="true" data-ajax-control-alert-succes="#contactSuccess" data-ajax-control-alert-unexpected="#contactErrorUnexpected" data-ajax-control-alert-mandaroty="#contactErrorMandatory" data-error-toast-text="<i class='fi fi-circle-spin fi-spin float-start'></i> Моля, попълнете всички задължителни полета!" data-error-toast-delay="5000" data-error-toast-position="bottom-center" class="">
                  <div class="form-label-group mb-3">
                    <input required="" placeholder="Name" id="contact_name" v-model="contact_name" name="contact_name" type="text" class="form-control" />
                    <label for="contact_name">{{ $t('contactsFormNames') }}</label>
                  </div>
                  <div class="form-label-group mb-3">
                    <input required="" placeholder="Phone" id="contact_phone" v-model="contact_phone" name="contact_phone" type="text" class="form-control" />
                    <label for="contact_phone">{{ $t('contactsFormMobile') }}</label>
                  </div>
                  <div class="form-label-group mb-4">
                    <textarea required="" placeholder="Message" id="contact_message" v-model="contact_message" name="contact_message" class="form-control" rows="3"></textarea>
                    <label for="contact_message">{{ $t('contactsFormMessage') }}</label>
                  </div>
                  <div class="clearfix bg-light position-relative rounded p-4 mb-4">
                    <span class="text-muted fs--12 d-block position-absolute bottom-0 end-0 m-2">EU GDPR</span>
                    <label class="form-checkbox form-checkbox-primary">
                      <input required="" type="checkbox" name="contact_gdpr" v-model="contact_gdpr" />
                      <i></i>
                      <span>{{ $t('contactsFormConsent') }} <router-link to="/privacy-policy" target="_blank">{{ $t('contactsFormPrivacyPolicy') }}</router-link>.</span>
                    </label>
                  </div>

                  <div id="ajax_dd_contact_response_container"></div>
                  <div id="contactErrorUnexpected" class="hide alert alert-danger p-3">{{ $t('contactsFormError') }}</div>
                  <div id="contactErrorMandatory" class="hide alert alert-danger p-3">{{ $t('contactsFormReview') }}</div>
                  <div id="contactSuccess" class="hide alert alert-success p-3">{{ $t('contactsFormThankYou') }}</div>
                  <button type="submit" class="btn btn-primary btn-block">{{ $t('contactsFormSendMessage') }}</button>
                </form>
              </div>
              <div class="col-12 col-lg-4 mb-4">
                <div class="d-flex">
                  <div class="w--40"><i class="fi fi-shape-abstract-dots text-gray-500 float-start fs--20"></i></div>
                  <div>
                    <h2 class="fs--25 font-weight-light">{{ $t('appName') }}</h2>
                    <ul class="list-unstyled m-0 fs--15">

                      <li class="mt--15 list-item text-muted">{{ $t('contactsCity') }}</li>
                    </ul>
                  </div>
                </div>
                <div class="d-flex mt-4">
                  <div class="w--40"><i class="fi fi-phone text-gray-500 float-start fs--20"></i></div>
                  <div>
                    <h3 class="h4 font-weight-normal">{{ $t('contactsMobile') }}</h3>
                    <ul class="list-unstyled m-0">
                      <li class="list-item mb-2 text-gray-500">
                        <a class="link-muted" :href="'tel:' + $t('contactsMainPhoneLink')">
                          <span>{{ $t('contactsMainPhone') }}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="d-flex mt-4">
                  <div class="w--40"><i class="fi fi-envelope text-gray-500 float-start fs--20"></i></div>
                  <div>
                    <h3 class="h4 font-weight-normal">{{ $t('contactsEmail') }}</h3>
                    <ul class="list-unstyled m-0">
                      <li class="list-item mb-2 text-gray-500">
                        <a class="link-muted" :href="'mailto:tybesty@abv.bg'">
                          <span>tybesty@abv.bg</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { title } from "../utils/utils";
import Menu from "./Menu.vue";
import emailjs from "emailjs-com";

export default {
  name: "Contacts",
  components: {
    Menu,
  },
  data() {
    return {
      contact_name: "",
      contact_phone: "",
      contact_message: "",
      contact_gdpr: "",
    };
  },
  created() {
    // document.title = "Home | Magnum Opus";
    document.title = title(this.$t("contactsHeader"), this.$t("appName"));
    // this.$emit('menu', 'test');
  },
  // TEST template - template_oau0qxn
  // PROD template - template_tiyqj85
  methods: {
    sendEmail() {
      emailjs
        .send(
          "service_amik0p8",
          "template_tiyqj85",
          {
            from_name: this.contact_name,
            phone: this.contact_phone,
            message: this.contact_message,
          },
          "user_XXy26smIvRJJm8HfSYT78"
        )
        .then(() => {
          alert("Email sent!");
          document.body.getElementById('contactSuccess').classList.remove("hide");
        });
    },
  },
};
</script>
