<template>
  <!-- 

								CALL TO ACTION

								Class Utils (top/bottom borders, if needed) 

									.border-top 
									.border-bottom

							-->
  <section class="py-5">

    <div class="container z-index-1">
      <div class="row text-center-xs">

        <div class="col-12 col-md-8">
          <h3 class="m-0">{{ $t('contactUsHeader') }}</h3>
          <p class="m-0">{{ $t('contactUsSubheader') }}</p>
        </div>

        <div class="col-12 mt-4 d-block d-md-none">
          <!-- mobile spacer -->
        </div>

        <div class="col-12 col-md-4 text-align-end">
          <router-link to="/contacts" class="btn btn-danger d-block-xs">{{ $t('contactUsButton') }}</router-link>
        </div>

      </div>
    </div>

    <!-- svg shape -->
    <svg class="absolute-full z-index-0 rounded-lg" width="100%" height="100%" viewBox="0 0 1920 90" preserveAspectRatio="none">
      <path fill="rgba(247, 52, 94,.04)" d="M1920,0C1217,0,120.574,155.567,0,0v90h1920V0z"></path>
    </svg>

  </section>
  <!-- /CALL TO ACTION -->

</template>

<script>
export default {
  name: "CallToAction",
};
</script>
