<template>
  <div>
    <Menu navGlobalMenu="products" navSubmenu="doors" />
    <section>
      <div class="container">
        <div class="row">
          <div class="col-12 col-xl-9">
            <h2>{{ $t('productsDoors') }}</h2>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb fs--14">
                <li class="breadcrumb-item">
                  <router-link to="/">{{ $t('homeHeader') }}</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/products">{{ $t('productsHeader') }}</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  <span>{{ $t('productsDoors') }}</span>
                </li>
              </ol>
            </nav>
            <hr />

            <h3 id="horizontal" class="mb-0">{{ $t('productsDoorsInterior') }}</h3>
            <hr />

            <div class="row">
              <div class="col-12">
                <p>{{ $t('productsDoorsInteriorDescr') }}</p>
              </div>

              <div class="col-12 col-md-6 mb-5">
                <div class="bg-white shadow-primary-xs transition-hover-top transition-all-ease-250">
                  <img class="img-fluid lazy rounded fillWidth" src="@/assets/images/products/doors/1.jpg" alt="..." data-loaded="true">
                </div>
              </div>
              <div class="col-12 col-md-6 mb-5">
                <div class="bg-white shadow-primary-xs transition-hover-top transition-all-ease-250">
                  <img class="img-fluid lazy rounded fillWidth" src="@/assets/images/products/doors/4.jpg" alt="..." data-loaded="true">
                </div>
              </div>
              <div class="col-12 col-md-6 mb-5">
                <div class="bg-white shadow-primary-xs transition-hover-top transition-all-ease-250">
                  <img class="img-fluid lazy rounded fillWidth" src="@/assets/images/products/doors/5.png" alt="..." data-loaded="true">
                </div>
              </div>
              <div class="col-12 col-md-6 mb-5">
                <div class="bg-white shadow-primary-xs transition-hover-top transition-all-ease-250">
                  <img class="img-fluid lazy rounded fillWidth" src="@/assets/images/products/doors/8.jpg" alt="..." data-loaded="true">
                </div>
              </div>
            </div>

            <h3 id="horizontal" class="mb-0">{{ $t('productsDoorsGarage') }}</h3>
            <hr />

            <div class="row">
              <div class="col-12">
                <p>{{ $t('productsDoorsGarageDescr') }}</p>
              </div>

              <div class="col-12 col-md-6 mb-5">
                <div class="bg-white shadow-primary-xs transition-hover-top transition-all-ease-250">
                  <img class="img-fluid lazy rounded fillWidth" src="@/assets/images/products/doors/9.jpg" alt="..." data-loaded="true">
                </div>
              </div>
              <div class="col-12 col-md-6 mb-5">
                <div class="bg-white shadow-primary-xs transition-hover-top transition-all-ease-250">
                  <img class="img-fluid lazy rounded fillWidth" src="@/assets/images/products/doors/12.jpg" alt="..." data-loaded="true">
                </div>
              </div>
              <div class="col-12 mb-5">
                <div class="bg-white shadow-primary-xs transition-hover-top transition-all-ease-250">
                  <img class="img-fluid lazy rounded fillWidth" src="@/assets/images/products/doors/10.jpg" alt="..." data-loaded="true">
                </div>
              </div>
              <div class="col-12 mb-5">
                <div class="bg-white shadow-primary-xs transition-hover-top transition-all-ease-250">
                  <img class="img-fluid lazy rounded fillWidth" src="@/assets/images/products/doors/11.jpg" alt="..." data-loaded="true">
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-3">
            <Sidebar globalMenu="products" submenu="doors" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <CallToAction />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { title } from "../../utils/utils";
import Menu from "../Menu.vue";
import Sidebar from "../Sidebar.vue";
import CallToAction from "../fragments/CallToAction.vue";

export default {
  name: "Doors",
  components: {
    Menu,
    Sidebar,
    CallToAction,
  },
  created() {
    // document.title = "Home | Magnum Opus";
    document.title = title(this.$t("productsDoors"), this.$t("appName"));
  },
};
</script>
