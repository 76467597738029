import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Home.vue'
import Contacts from '../components/Contacts.vue'
import Products from '../components/products/Products.vue'
import Blinds from '../components/products/Blinds.vue'
import AirConditions from '../components/products/AirConditions.vue'
import InfraredPanels from '../components/products/InfraredPanels.vue'
import Thermopaints from '../components/products/Thermopaints.vue'
import Windows from '../components/products/Windows.vue'
import Nets from '../components/products/Nets.vue'
import Doors from '../components/products/Doors.vue'
import TermsAndConditions from '../components/TermsAndConditions.vue'
import PrivacyPolicy from '../components/PrivacyPolicy.vue'
import NotFound from '../components/NotFound.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/contacts',
        name: 'Contacts',
        component: Contacts
    },
    {
        path: '/products',
        name: 'Products',
        component: Products
    },
    {
        path: '/products/blinds',
        name: 'Blinds',
        component: Blinds
    },
    {
        path: '/products/air-conditions',
        name: 'AirConditions',
        component: AirConditions
    },
    {
        path: '/products/infrared-panels',
        name: 'InfraredPanels',
        component: InfraredPanels
    },
    {
        path: '/products/thermo-paints',
        name: 'Thermopaints',
        component: Thermopaints
    },
    {
        path: '/products/windows',
        name: 'Windows',
        component: Windows
    },
    {
        path: '/products/nets',
        name: 'Nets',
        component: Nets
    },
    {
        path: '/products/doors',
        name: 'Doors',
        component: Doors
    },
    {
        path: '/terms-and-conditions',
        name: 'TermsAndConditions',
        component: TermsAndConditions
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy
    },
    {
        path: '/404',
        component: NotFound
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/404'
    },
]

const router = createRouter({
    mode: "history",
    // history: createWebHistory(process.env.BASE_URL),
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView();
    }
});

export default router