<template>
  <div>
    <Menu navGlobalMenu="home" />
    <section class="pt-0">
      <div class="container pt-5">

        <div class="row text-center-xs align-items-center">

          <div class="col-12 col-md-6 order-2 order-md-1 pb-5 aos-init aos-animate" data-aos="fade-in" data-aos-delay="0">

            <div class="mb-5">

              <h1 class="font-weight-bold mb-5">

                <em class="position-relative display-4 h2-xs font-weight-bold d-block">
                  {{ $t('appName') }}
                </em>

                <span class="position-relative h5-xs d-inline-block font-weight-medium">
                  <em class="text-danger-gradient">{{ $t('homeHeader1')}}</em> {{$t('homeHeader2')}}

                  <!-- svg drawlines -->
                  <svg class="svg-drawlines h--20 position-absolute start-0 bottom-0 mb--n15" viewBox="0 0 154 13">
                    <path fill="none" d="M2 2c49.7 2.6 100 3.1 150 1.7-46.5 2-93 4.4-139.2 7.3 45.2-1.5 90.6-1.8 135.8-.6" vector-effect="non-scaling-stroke" stroke-linejoin="round" stroke-linecap="round"></path>
                  </svg>
                </span>

              </h1>

              <p class="customLead" v-html="$t('homeAboutUs')"></p>

              <div class="mt-5">

                <router-link class="btn btn-danger shadow-danger-xlg row-pill font-weight-medium" to="/products">
                  <span>{{ $t('homeProducts') }}</span>
                </router-link>
              </div>
            </div>
          </div>

          <!-- image -->
          <div class="col-12 col-md-6 order-1 order-md-2 pb-5 align-items-center aos-init aos-animate" data-aos="fade-in" data-aos-delay="200">
            <img width="600" height="400" class="img-fluid lazy px-5" src="@/assets/images/business.svg" alt="..." data-loaded="true">

          </div>

        </div>

      </div>
    </section>
  </div>
</template>

<script>
import Menu from "../components/Menu.vue";
import { title } from "../utils/utils";

export default {
  name: "Home",
  components: {
    Menu,
  },
  created() {
    // document.title = "Home | Magnum Opus";
    document.title = title(this.$t("homeHeader"), this.$t("appName"));
  },
};
</script>

<style>
@import "../assets/css/core.css";
@import "../assets/css/vendor_bundle.min.css";
@import "../assets/css/vendor.photoswipe.css";
@import "../assets/css/style.css";
</style>

