<template>

  <header id="header" class="shadow-xs">
    <!-- TOP BAR -->
    <div id="top_bar" class="bg-light fs--14">
      <!-- optional if body.header-sticky is present: add .js-ignore class to ignore autohide and stay visible all the time -->
      <div class="container">
        <div class="text-nowrap">
          <!-- change with .scrollable-horizontal to horizontally scroll, if -only- no dropdown is present -->
          <div class="d-flex justify-content-between">
            <div class="d-inline-block float-start">
              <ul class="list-inline m-0">
                <!-- LANGUAGE -->
                <li class="dropdown list-inline-item m-0">
                  <a id="topDDLanguage" href="#!" class="py-2 d-inline-block js-stoppropag" data-toggle="dropdown" aria-expanded="false" aria-haspopup="true">
                    <i :class="'flag flag-' + $t('flag')"></i>
                    <span class="text-muted pl-2 pr-2">{{ $t('currentLang') }}</span>
                  </a>

                  <div aria-labelledby="topDDLanguage" class="dropdown-menu dropdown-menu-hover text-uppercase fs--13 px-1 pt-1 pb-0 m-0 max-h-50vh scrollable-vertical">
                    <a href="#" @click="changeLocale('bg')" class="dropdown-item text-muted text-truncate line-height-1 rounded p--12 mb-1" :class="{ 'active': $t('flag') === 'bg' }">
                      <i class="flag flag-bg"></i>
                      <span>{{ $t('langBG') }}</span>
                    </a>
                    <a href="#" @click="changeLocale('en')" class="dropdown-item text-muted text-truncate line-height-1 rounded p--12 mb-1" :class="{ 'active': $t('flag') === 'gb' }">
                      <i class="flag flag-gb"></i>
                      <span>{{ $t('langEN') }}</span>
                    </a>
                  </div>
                </li>
                <!-- /LANGUAGE -->

                <!-- CURRENCY -->
                <!-- <li class="dropdown list-inline-item m-0"> -->
                <!-- <span class="text-muted">/</span> -->
                <!-- optional separator -->
                <!-- <a id="topDDCurrency" href="#" class="py-2 d-inline-block js-stoppropag" data-toggle="dropdown" aria-expanded="false"> -->
                <!-- <span class="text-muted pl-2 pr-2">USD</span> -->
                <!-- </a> -->
                <!--  -->
                <!-- <div aria-labelledby="topDDCurrency" class="dropdown-menu dropdown-menu-hover text-uppercase text-center fs--13 px-1 pt-1 pb-0 m-0 max-h-50vh w-auto scrollable-vertical"> -->
                <!-- <a href="#!" class="active dropdown-item text-muted text-truncate line-height-1 rounded pt--12 pb--12 mb-1"> -->
                <!-- <span>USD</span> -->
                <!-- </a> -->
                <!-- <a href="#!" class="dropdown-item text-muted text-truncate line-height-1 rounded pt--12 pb--12 mb-1"> -->
                <!-- <span>EUR</span> -->
                <!-- </a> -->
                <!-- <a href="#!" class="dropdown-item text-muted text-truncate line-height-1 rounded pt--12 pb--12 mb-1"> -->
                <!-- <span>GBP</span> -->
                <!-- </a> -->
                <!-- </div> -->
                <!-- </li> -->
                <!-- /CURRENCY -->
              </ul>
            </div>

            <div class="d-none d-md-inline-block float-end">
              <ul class="list-inline m-0">
                <li class="dropdown list-inline-item">
                  <a :href="'tel:' + $t('contactsMainPhoneLink')" class="p-2 d-inline-block font-weight-medium">
                    <i class="float-start fi fi-phone"></i>
                    <span>{{ $t('contactsMainPhone') }}</span>
                  </a>
                  <!-- <a href="mailto:customer-service@magnumopusbg.eu" class="p-2 d-inline-block font-weight-medium">
                                    <i class="float-start fi fi-envelope"></i>
                                    <span>customer-service@magnumopusbg.eu</span>
                                </a> -->
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /TOP BAR -->

    <!-- NAVBAR -->
    <div class="container position-relative">
      <nav class="navbar navbar-expand-lg navbar-light justify-content-lg-between justify-content-md-inherit">
        <div class="align-items-start">
          <!-- mobile menu button : show -->
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarMainNav" aria-controls="navbarMainNav" aria-expanded="false" aria-label="Toggle navigation">
            <svg width="25" viewBox="0 0 20 20">
              <path d="M 19.9876 1.998 L -0.0108 1.998 L -0.0108 -0.0019 L 19.9876 -0.0019 L 19.9876 1.998 Z"></path>
              <path d="M 19.9876 7.9979 L -0.0108 7.9979 L -0.0108 5.9979 L 19.9876 5.9979 L 19.9876 7.9979 Z"></path>
              <path d="M 19.9876 13.9977 L -0.0108 13.9977 L -0.0108 11.9978 L 19.9876 11.9978 L 19.9876 13.9977 Z"></path>
              <path d="M 19.9876 19.9976 L -0.0108 19.9976 L -0.0108 17.9976 L 19.9876 17.9976 L 19.9876 19.9976 Z"></path>
            </svg>
          </button>

          <router-link class="navbar-brand" to="/">
            <img src="@/assets/images/logo.png" alt="..." />
          </router-link>

        </div>

        <div class="collapse navbar-collapse navbar-animate-fadein" id="navbarMainNav">
          <!-- MOBILE MENU NAVBAR -->
          <div class="navbar-xs d-none">
            <!-- .sticky-top -->

            <!-- mobile menu button : close -->
            <button class="navbar-toggler pt-0" type="button" data-toggle="collapse" data-target="#navbarMainNav" aria-controls="navbarMainNav" aria-expanded="false" aria-label="Toggle navigation">
              <svg width="20" viewBox="0 0 20 20">
                <path d="M 20.7895 0.977 L 19.3752 -0.4364 L 10.081 8.8522 L 0.7869 -0.4364 L -0.6274 0.977 L 8.6668 10.2656 L -0.6274 19.5542 L 0.7869 20.9676 L 10.081 11.679 L 19.3752 20.9676 L 20.7895 19.5542 L 11.4953 10.2656 L 20.7895 0.977 Z"></path>
              </svg>
            </button>

            <!-- Mobile Menu Logo : height: 70px max -->
            <router-link class="navbar-brand" to="/">
              <img src="@/assets/images/logo.png" alt="..." />
            </router-link>

          </div>
          <!-- /MOBILE MENU NAVBAR -->

          <!-- NAVIGATION -->
          <ul class="navbar-nav">
            <!-- Add class 'active' to <li> item to make it active -->
            <li class="nav-item" :class="{ 'active': navGlobalMenu === 'home' }">
              <router-link class="nav-link js-stoppropag" to="/">{{ $t('menuHome') }}</router-link>
            </li>

            <li class="nav-item dropdown" :class="{ 'active': navGlobalMenu === 'products' }">

              <a href="" class="nav-link dropdown-toggle js-stoppropag" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span>{{ $t('menuProducts') }}</span>
              </a>

              <div aria-labelledby="mainNavHome" class="dropdown-menu dropdown-menu-clean dropdown-menu-hover">
                <ul class="list-unstyled m-0 p-0">
                  <li class="dropdown-item" :class="{ 'active': navGlobalMenu === 'products' && navSubmenu === 'products'}">
                    <router-link class="dropdown-link" to="/products">{{ $t('menuProducts') }}</router-link>
                  </li>
                  <li class="dropdown-divider"></li>
                  <li class="dropdown-item" :class="{ 'active': navGlobalMenu === 'products' && navSubmenu === 'blinds'}">
                    <router-link class="dropdown-link" to="/products/blinds">{{ $t('menuProductsBlinds') }}</router-link>
                  </li>
                  <li class="dropdown-item" :class="{ 'active': navGlobalMenu === 'products' && navSubmenu === 'airConditions'}">
                    <router-link class="dropdown-link" to="/products/air-conditions">{{ $t('menuProductsAirConditions') }}</router-link>
                  </li>
                  <li class="dropdown-item" :class="{ 'active': navGlobalMenu === 'products' && navSubmenu === 'infraredPanels'}">
                    <router-link class="dropdown-link" to="/products/infrared-panels">{{ $t('menuProductsInfraredPanels') }}</router-link>
                  </li>
                  <li class="dropdown-item" :class="{ 'active': navGlobalMenu === 'products' && navSubmenu === 'thermoPaints'}">
                    <router-link class="dropdown-link" to="/products/thermo-paints">{{ $t('menuProductsThermoPaints') }}</router-link>
                  </li>
                  <li class="dropdown-item" :class="{ 'active': navGlobalMenu === 'products' && navSubmenu === 'windows'}">
                    <router-link class="dropdown-link" to="/products/windows">{{ $t('menuProductsWindows') }}</router-link>
                  </li>
                  <!-- <li class="dropdown-item dropdown">
                    <a href="#" class="dropdown-link js-stoppropag" data-toggle="dropdown">{{ $t('menuProductsWindows') }}</a>
                    <ul class="dropdown-menu dropdown-menu-hover dropdown-menu-block-md shadow-lg b-0 m-0">
                      <li class="dropdown-item">
                        <a href="/products/windows/pvc" class="dropdown-link">{{ $t('menuProductsWindowsPVC') }}</a>
                      </li>
                      <li class="dropdown-item">
                        <a href="/products/windows/aluplast" class="dropdown-link">{{ $t('menuProductsWindowsAluplast') }}</a>
                      </li>
                      <li class="dropdown-item">
                        <a href="/products/windows/aluminium" class="dropdown-link">{{ $t('menuProductsWindowsAluminium') }}</a>
                      </li>
                    </ul>
                  </li> -->
                  <li class="dropdown-item" :class="{ 'active': navGlobalMenu === 'products' && navSubmenu === 'nets'}">
                    <router-link class="dropdown-link" to="/products/nets">{{ $t('menuProductsNets') }}</router-link>
                  </li>
                  <li class="dropdown-item" :class="{ 'active': navGlobalMenu === 'products' && navSubmenu === 'doors'}">
                    <router-link class="dropdown-link" to="/products/doors">{{ $t('menuProductsDoors') }}</router-link>
                  </li>
                </ul>
              </div>
            </li>

            <li class="nav-item" :class="{ 'active': navGlobalMenu === 'contacts'}">
              <router-link class="nav-link js-stoppropag" to="/contacts">{{ $t('menuContacts') }}</router-link>
            </li>
          </ul>
          <!-- /NAVIGATION -->
        </div>
      </nav>
    </div>
    <!-- /NAVBAR -->

    <!-- <select v-model="$i18n.locale">
      <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale }}</option>
    </select> -->
  </header>

</template>

<script>
import i18n from "../plugins/i18n";
import cookies from 'vue-cookies';

export default {
  name: "Menu",
  props: {
    navGlobalMenu: String,
    navSubmenu: String,
  },
  methods: {
    changeLocale(locale) {
      i18n.global.locale = locale;
      cookies.set('locale', locale);
    },
  },
};
</script>
