<template>
  <nav class="nav-deep nav-deep-light mb-2 js-ajaxified">

    <!-- desktop only -->
    <h3 class="h5 pt-3 pb-3 m-0">{{ $t('menuProducts') }}</h3>

    <!-- navigation -->
    <ul id="nav_responsive" class="nav flex-column">

      <li class="nav-item" :class="{ 'active': globalMenu === 'products' && submenu === 'blinds' }">
        <router-link class="nav-link px-0" to="/products/blinds">
          <i class="fi fi-arrow-end m-0 fs--12"></i>
          <span class="px-2 d-inline-block">{{ $t('menuProductsBlinds') }}</span>
        </router-link>
      </li>

      <li class="nav-item" :class="{ 'active': globalMenu === 'products' && submenu === 'airConditions' }">
        <router-link class="nav-link px-0" to="/products/air-conditions">
          <i class="fi fi-arrow-end m-0 fs--12"></i>
          <span class="px-2 d-inline-block">{{ $t('menuProductsAirConditions') }}</span>
        </router-link>
      </li>

      <li class="nav-item" :class="{ 'active': globalMenu === 'products' && submenu === 'infraredPanels' }">
        <router-link class="nav-link px-0" to="/products/infrared-panels">
          <i class="fi fi-arrow-end m-0 fs--12"></i>
          <span class="px-2 d-inline-block">{{ $t('menuProductsInfraredPanelsShort') }}</span>
        </router-link>
      </li>

      <li class="nav-item" :class="{ 'active': globalMenu === 'products' && submenu === 'thermoPaints' }">
        <router-link class="nav-link px-0" to="/products/thermo-paints">
          <i class="fi fi-arrow-end m-0 fs--12"></i>
          <span class="px-2 d-inline-block">{{ $t('menuProductsThermoPaintsShort') }}</span>
        </router-link>
      </li>

      <li class="nav-item" :class="{ 'active': globalMenu === 'products' && submenu === 'windows' }">
        <router-link class="nav-link px-0" to="/products/windows">
          <i class="fi fi-arrow-end m-0 fs--12"></i>
          <span class="px-2 d-inline-block">{{ $t('menuProductsWindows') }}</span>
        </router-link>
      </li>

      <li class="nav-item" :class="{ 'active': globalMenu === 'products' && submenu === 'nets' }">
        <router-link class="nav-link px-0" to="/products/nets">
          <i class="fi fi-arrow-end m-0 fs--12"></i>
          <span class="px-2 d-inline-block">{{ $t('menuProductsNets') }}</span>
        </router-link>
      </li>

      <li class="nav-item" :class="{ 'active': globalMenu === 'products' && submenu === 'doors' }">
        <router-link class="nav-link px-0" to="/products/doors">
          <i class="fi fi-arrow-end m-0 fs--12"></i>
          <span class="px-2 d-inline-block">{{ $t('menuProductsDoors') }}</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Sidebar",
  props: {
    globalMenu: String,
    submenu: String,
  },
};
</script>
