<template>
  <div>
    <Menu navGlobalMenu="products" navSubmenu="nets" />
    <section>
      <div class="container">
        <div class="row">
          <div class="col-12 col-xl-9">
            <h2>{{ $t('productsNets') }}</h2>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb fs--14">
                <li class="breadcrumb-item">
                  <router-link to="/">{{ $t('homeHeader') }}</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/products">{{ $t('productsHeader') }}</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  <span>{{ $t('productsNets') }}</span>
                </li>
              </ol>
            </nav>
            <hr />

            <div class="row">
              <div class="col-12 col-lg-5 order-1">
                <p>{{ $t('productsNetsDescr') }}</p>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">{{ $t('productsNetsStatic') }}</li>
                  <li class="list-group-item">{{ $t('productsNetsHinged') }}</li>
                  <li class="list-group-item">{{ $t('productsNetsRoller') }}</li>
                </ul>
              </div>
              <div class="col-12 col-lg-7 order-2">
                <div class="bg-white shadow-primary-xs mb-3">
                  <img height="500" class="img-fluid lazy rounded fillWidth" src="@/assets/images/products/nets/3.jpg" alt="..." data-loaded="true">
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-3">
            <Sidebar globalMenu="products" submenu="nets" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <CallToAction />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { title } from "../../utils/utils";
import Menu from "../Menu.vue";
import Sidebar from "../Sidebar.vue";
import CallToAction from "../fragments/CallToAction.vue";

export default {
  name: "Nets",
  components: {
    Menu,
    Sidebar,
    CallToAction,
  },
  created() {
    // document.title = "Home | Magnum Opus";
    document.title = title(this.$t("productsNets"), this.$t("appName"));
  },
};
</script>
