<template>
  <div>
    <Menu />
    <section>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2>{{ $t('menuPrivacyPolicy') }}</h2>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb fs--14">
                <li class="breadcrumb-item">
                  <router-link to="/">{{ $t('homeHeader') }}</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  <span>{{ $t('menuPrivacyPolicy') }}</span>
                </li>
              </ol>
            </nav>
            <hr />
            <p class="mb-4" v-html="$t('privacyPolicy')"></p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Menu from "./Menu.vue";
import { title } from "../utils/utils";

export default {
  name: "PrivacyPolicy",
  props: {
    msg: String,
  },
  components: {
    Menu,
  },
  created() {
    // document.title = "Home | Magnum Opus";
    document.title = title(this.$t("privacyPolicyHeader"), this.$t("appName"));
  },
};
</script>
